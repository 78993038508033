import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Tirreno - Adriatico",
      edition: 2022,
      what: "Race data",
      updated: "2022-03-07 18:17:44",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citroën Team",
        teamNationCode: "FRA",
      },
      21803: {
        teamId: "21803",
        teamUciCode: "AST",
        teamName: "Astana Qazaqstan Team",
        teamNationCode: "KAZ",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21809: {
        teamId: "21809",
        teamUciCode: "QST",
        teamName: "Quick-Step Alpha Vinyl Team",
        teamNationCode: "BEL",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21814: {
        teamId: "21814",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21823: {
        teamId: "21823",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21827: {
        teamId: "21827",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21830: {
        teamId: "21830",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21845: {
        teamId: "21845",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21847: {
        teamId: "21847",
        teamUciCode: "DRA",
        teamName: "Drone Hopper-Androni Giocattoli",
        teamNationCode: "ITA",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        teamNationCode: "BEL",
      },
      21865: {
        teamId: "21865",
        teamUciCode: "ARK",
        teamName: "Team Arkéa-Samsic",
        teamNationCode: "FRA",
      },
      21868: {
        teamId: "21868",
        teamUciCode: "TEN",
        teamName: "TotalEnergies",
        teamNationCode: "FRA",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22290: {
        teamId: "22290",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      22480: {
        teamId: "22480",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      22591: {
        teamId: "22591",
        teamUciCode: "BCF",
        teamName: "Bardiani CSF Faizane",
        teamNationCode: "ITA",
      },
      23694: {
        teamId: "23694",
        teamUciCode: "EOK",
        teamName: "EOLO-Kometa Cycling Team",
        teamNationCode: "ITA",
      },
    },
    riders: {
      563: {
        id: 563,
        startno: 125,
        firstName: "Richie",
        lastName: "Porte",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1985-01-30",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 25,
          overallPosition: 25,
          stageTime: "00:00:51",
          overallTime: "00:00:51",
        },
      },
      38195: {
        id: 38195,
        startno: 151,
        firstName: "Jonas",
        lastName: "Vingegaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-12-10",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 27,
          overallPosition: 27,
          stageTime: "00:00:53",
          overallTime: "00:00:53",
        },
      },
      2337: {
        id: 2337,
        startno: 112,
        firstName: "Arnaud",
        lastName: "Démare",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-08-26",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 31,
          overallPosition: 31,
          stageTime: "00:00:57",
          overallTime: "00:00:57",
        },
      },
      27277: {
        id: 27277,
        startno: 37,
        firstName: "Simone",
        lastName: "Velasco",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-12-02",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 45,
          overallPosition: 45,
          stageTime: "00:01:03",
          overallTime: "00:01:03",
        },
      },
      50047: {
        id: 50047,
        startno: 103,
        firstName: "Davide",
        lastName: "Bais",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-04-02",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 135,
          overallPosition: 135,
          stageTime: "00:01:54",
          overallTime: "00:01:54",
        },
      },
      27043: {
        id: 27043,
        startno: 95,
        firstName: "Mark",
        lastName: "Padun",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1996-07-06",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 38,
          overallPosition: 38,
          stageTime: "00:01:02",
          overallTime: "00:01:02",
        },
      },
      120: {
        id: 120,
        startno: 44,
        firstName: "Heinrich",
        lastName: "Haussler",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1984-02-25",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 97,
          overallPosition: 97,
          stageTime: "00:01:29",
          overallTime: "00:01:29",
        },
      },
      23097: {
        id: 23097,
        startno: 121,
        firstName: "Richard",
        lastName: "Carapaz",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-05-29",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 20,
          overallPosition: 20,
          stageTime: "00:00:47",
          overallTime: "00:00:47",
        },
      },
      3045: {
        id: 3045,
        startno: 134,
        firstName: "Adrien",
        lastName: "Petit",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-09-26",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 80,
          overallPosition: 80,
          stageTime: "00:01:20",
          overallTime: "00:01:20",
        },
      },
      605: {
        id: 605,
        startno: 114,
        firstName: "Jacopo",
        lastName: "Guarnieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-14",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 114,
          overallPosition: 114,
          stageTime: "00:01:37",
          overallTime: "00:01:37",
        },
      },
      6413: {
        id: 6413,
        startno: 15,
        firstName: "Bob",
        lastName: "Jungels",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-09-22",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 35,
          overallPosition: 35,
          stageTime: "00:01:00",
          overallTime: "00:01:00",
        },
      },
      150: {
        id: 150,
        startno: 11,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 96,
          overallPosition: 96,
          stageTime: "00:01:29",
          overallTime: "00:01:29",
        },
      },
      27307: {
        id: 27307,
        startno: 172,
        firstName: "Alex",
        lastName: "Aranburu",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-09-19",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 81,
          overallPosition: 81,
          stageTime: "00:01:21",
          overallTime: "00:01:21",
        },
      },
      9746: {
        id: 9746,
        startno: 2,
        firstName: "Pascal",
        lastName: "Ackermann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-17",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 105,
          overallPosition: 105,
          stageTime: "00:01:32",
          overallTime: "00:01:32",
        },
      },
      40783: {
        id: 40783,
        startno: 207,
        firstName: "Matteo",
        lastName: "Sobrero",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-05-14",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 10,
          overallPosition: 10,
          stageTime: "00:00:39",
          overallTime: "00:00:39",
        },
      },
      4589: {
        id: 4589,
        startno: 117,
        firstName: "Ramon",
        lastName: "Sinkeldam",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-02-09",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 125,
          overallPosition: 125,
          stageTime: "00:01:46",
          overallTime: "00:01:46",
        },
      },
      8377: {
        id: 8377,
        startno: 21,
        firstName: "Tim",
        lastName: "Merlier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-10-30",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 107,
          overallPosition: 107,
          stageTime: "00:01:32",
          overallTime: "00:01:32",
        },
      },
      2275: {
        id: 2275,
        startno: 211,
        firstName: "Romain",
        lastName: "Bardet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-11-09",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 100,
          overallPosition: 100,
          stageTime: "00:01:30",
          overallTime: "00:01:30",
        },
      },
      6414: {
        id: 6414,
        startno: 47,
        firstName: "Jasha",
        lastName: "Sütterlin",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-04",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 28,
          overallPosition: 28,
          stageTime: "00:00:53",
          overallTime: "00:00:53",
        },
      },
      67803: {
        id: 67803,
        startno: 56,
        firstName: "Luca",
        lastName: "Rastelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-12-29",
        teamId: 22591,
        teamName: "Bardiani CSF Faizane",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 138,
          overallPosition: 138,
          stageTime: "00:01:56",
          overallTime: "00:01:56",
        },
      },
      3991: {
        id: 3991,
        startno: 5,
        firstName: "Rafal",
        lastName: "Majka",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1989-09-12",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 106,
          overallPosition: 106,
          stageTime: "00:01:32",
          overallTime: "00:01:32",
        },
      },
      37868: {
        id: 37868,
        startno: 52,
        firstName: "Johnatan",
        lastName: "Ca\u00c3\u00b1averal",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-11-02",
        teamId: 22591,
        teamName: "Bardiani CSF Faizane",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 166,
          overallPosition: 166,
          stageTime: "00:02:41",
          overallTime: "00:02:41",
        },
      },
      43007: {
        id: 43007,
        startno: 71,
        firstName: "Benjamin",
        lastName: "Thomas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-09-12",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 12,
          overallPosition: 12,
          stageTime: "00:00:42",
          overallTime: "00:00:42",
        },
      },
      780: {
        id: 780,
        startno: 73,
        firstName: "Davide",
        lastName: "Cimolai",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-13",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 145,
          overallPosition: 145,
          stageTime: "00:02:05",
          overallTime: "00:02:05",
        },
      },
      27268: {
        id: 27268,
        startno: 64,
        firstName: "Matteo",
        lastName: "Fabbro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-04-10",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 65,
          overallPosition: 65,
          stageTime: "00:01:13",
          overallTime: "00:01:13",
        },
      },
      3050: {
        id: 3050,
        startno: 46,
        firstName: "Jan",
        lastName: "Tratnik",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1990-02-23",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 34,
          overallPosition: 34,
          stageTime: "00:00:59",
          overallTime: "00:00:59",
        },
      },
      44622: {
        id: 44622,
        startno: 166,
        firstName: "Harry",
        lastName: "Sweeny",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-07-09",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 44,
          overallPosition: 44,
          stageTime: "00:01:03",
          overallTime: "00:01:03",
        },
      },
      38481: {
        id: 38481,
        startno: 174,
        firstName: "Mathias",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-05-05",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 112,
          overallPosition: 112,
          stageTime: "00:01:36",
          overallTime: "00:01:36",
        },
      },
      46959: {
        id: 46959,
        startno: 197,
        firstName: "Clément",
        lastName: "Russo",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-01-20",
        teamId: 21865,
        teamName: "Team Arkéa-Samsic",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 59,
          overallPosition: 59,
          stageTime: "00:01:10",
          overallTime: "00:01:10",
        },
      },
      486: {
        id: 486,
        startno: 51,
        firstName: "Giovanni",
        lastName: "Visconti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1983-01-13",
        teamId: 22591,
        teamName: "Bardiani CSF Faizane",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 160,
          overallPosition: 160,
          stageTime: "00:02:24",
          overallTime: "00:02:24",
        },
      },
      22010: {
        id: 22010,
        startno: 107,
        firstName: "Mirco",
        lastName: "Maestri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-10-26",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 84,
          overallPosition: 84,
          stageTime: "00:01:22",
          overallTime: "00:01:22",
        },
      },
      3355: {
        id: 3355,
        startno: 202,
        firstName: "Lawson",
        lastName: "Craddock",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-02-20",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 11,
          overallPosition: 11,
          stageTime: "00:00:39",
          overallTime: "00:00:39",
        },
      },
      875: {
        id: 875,
        startno: 142,
        firstName: "Matthias",
        lastName: "Brändle",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1989-12-07",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 33,
          overallPosition: 33,
          stageTime: "00:00:58",
          overallTime: "00:00:58",
        },
      },
      68868: {
        id: 68868,
        startno: 82,
        firstName: "Alexander",
        lastName: "Cepeda",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1998-06-16",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 165,
          overallPosition: 165,
          stageTime: "00:02:37",
          overallTime: "00:02:37",
        },
      },
      16154: {
        id: 16154,
        startno: 195,
        firstName: "Daniel",
        lastName: "McLay",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-01-03",
        teamId: 21865,
        teamName: "Team Arkéa-Samsic",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 122,
          overallPosition: 122,
          stageTime: "00:01:42",
          overallTime: "00:01:42",
        },
      },
      27275: {
        id: 27275,
        startno: 136,
        firstName: "Lorenzo",
        lastName: "Rota",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-05-23",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 77,
          overallPosition: 77,
          stageTime: "00:01:19",
          overallTime: "00:01:19",
        },
      },
      729: {
        id: 729,
        startno: 157,
        firstName: "Jos",
        lastName: "Van Emden",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-02-18",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 8,
          overallPosition: 8,
          stageTime: "00:00:33",
          overallTime: "00:00:33",
        },
      },
      7964: {
        id: 7964,
        startno: 27,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 152,
          overallPosition: 152,
          stageTime: "00:02:18",
          overallTime: "00:02:18",
        },
      },
      16947: {
        id: 16947,
        startno: 226,
        firstName: "Cristian",
        lastName: "Rodriguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-03-03",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 98,
          overallPosition: 98,
          stageTime: "00:01:29",
          overallTime: "00:01:29",
        },
      },
      3226: {
        id: 3226,
        startno: 164,
        firstName: "Michael",
        lastName: "Schwarzmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-01-07",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 159,
          overallPosition: 159,
          stageTime: "00:02:23",
          overallTime: "00:02:23",
        },
      },
      1579: {
        id: 1579,
        startno: 55,
        firstName: "Sacha",
        lastName: "Modolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-06-19",
        teamId: 22591,
        teamName: "Bardiani CSF Faizane",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 161,
          overallPosition: 161,
          stageTime: "00:02:28",
          overallTime: "00:02:28",
        },
      },
      31: {
        id: 31,
        startno: 222,
        firstName: "Edvald",
        lastName: "Boasson-Hagen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-05-17",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 89,
          overallPosition: 89,
          stageTime: "00:01:23",
          overallTime: "00:01:23",
        },
      },
      77970: {
        id: 77970,
        startno: 177,
        firstName: "Einer",
        lastName: "Rubio",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1998-02-22",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 164,
          overallPosition: 164,
          stageTime: "00:02:36",
          overallTime: "00:02:36",
        },
      },
      34011: {
        id: 34011,
        startno: 14,
        firstName: "Benoît",
        lastName: "Cosnefroy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-10-17",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 94,
          overallPosition: 94,
          stageTime: "00:01:26",
          overallTime: "00:01:26",
        },
      },
      27097: {
        id: 27097,
        startno: 152,
        firstName: "Edoardo",
        lastName: "Affini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-06-24",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 15,
          overallPosition: 15,
          stageTime: "00:00:44",
          overallTime: "00:00:44",
        },
      },
      20581: {
        id: 20581,
        startno: 32,
        firstName: "Leonardo",
        lastName: "Basso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-12-25",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 127,
          overallPosition: 127,
          stageTime: "00:01:46",
          overallTime: "00:01:46",
        },
      },
      1008: {
        id: 1008,
        startno: 176,
        firstName: "Nelson",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1989-03-06",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 16,
          overallPosition: 16,
          stageTime: "00:00:44",
          overallTime: "00:00:44",
        },
      },
      42185: {
        id: 42185,
        startno: 215,
        firstName: "Chris",
        lastName: "Hamilton",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-05-18",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 71,
          overallPosition: 71,
          stageTime: "00:01:16",
          overallTime: "00:01:16",
        },
      },
      66265: {
        id: 66265,
        startno: 3,
        firstName: "Mikkel",
        lastName: "Bjerg",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-11-03",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 9,
          overallPosition: 9,
          stageTime: "00:00:39",
          overallTime: "00:00:39",
        },
      },
      860: {
        id: 860,
        startno: 111,
        firstName: "Thibaut",
        lastName: "Pinot",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-05-29",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 91,
          overallPosition: 91,
          stageTime: "00:01:25",
          overallTime: "00:01:25",
        },
      },
      28781: {
        id: 28781,
        startno: 35,
        firstName: "Gianni",
        lastName: "Moscon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-20",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 63,
          overallPosition: 63,
          stageTime: "00:01:12",
          overallTime: "00:01:12",
        },
      },
      37351: {
        id: 37351,
        startno: 102,
        firstName: "Vincenzo",
        lastName: "Albanese",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-11-12",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 102,
          overallPosition: 102,
          stageTime: "00:01:30",
          overallTime: "00:01:30",
        },
      },
      4317: {
        id: 4317,
        startno: 116,
        firstName: "Tobias",
        lastName: "Ludvigsson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1991-02-22",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 7,
          overallPosition: 7,
          stageTime: "00:00:32",
          overallTime: "00:00:32",
        },
      },
      61988: {
        id: 61988,
        startno: 205,
        firstName: "Kaden",
        lastName: "Groves",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-12-23",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 49,
          overallPosition: 49,
          stageTime: "00:01:06",
          overallTime: "00:01:06",
        },
      },
      6309: {
        id: 6309,
        startno: 93,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 29,
          overallPosition: 29,
          stageTime: "00:00:54",
          overallTime: "00:00:54",
        },
      },
      39175: {
        id: 39175,
        startno: 196,
        firstName: "Alan",
        lastName: "Riou",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-04-02",
        teamId: 21865,
        teamName: "Team Arkéa-Samsic",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 55,
          overallPosition: 55,
          stageTime: "00:01:08",
          overallTime: "00:01:08",
        },
      },
      27271: {
        id: 27271,
        startno: 104,
        firstName: "Lorenzo",
        lastName: "Fortunato",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-05-09",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 121,
          overallPosition: 121,
          stageTime: "00:01:42",
          overallTime: "00:01:42",
        },
      },
      341: {
        id: 341,
        startno: 115,
        firstName: "Ignatas",
        lastName: "Konovalovas",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1985-12-08",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 90,
          overallPosition: 90,
          stageTime: "00:01:24",
          overallTime: "00:01:24",
        },
      },
      84019: {
        id: 84019,
        startno: 186,
        firstName: "Remco",
        lastName: "Evenepoel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-01-25",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 2,
          overallPosition: 2,
          stageTime: "00:00:11",
          overallTime: "00:00:11",
        },
      },
      1527: {
        id: 1527,
        startno: 171,
        firstName: "Enric",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-01-07",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 58,
          overallPosition: 58,
          stageTime: "00:01:10",
          overallTime: "00:01:10",
        },
      },
      5306: {
        id: 5306,
        startno: 213,
        firstName: "Nikias",
        lastName: "Arndt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-11-18",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 74,
          overallPosition: 74,
          stageTime: "00:01:17",
          overallTime: "00:01:17",
        },
      },
      39286: {
        id: 39286,
        startno: 206,
        firstName: "Alexander",
        lastName: "Konychev",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-07-25",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 133,
          overallPosition: 133,
          stageTime: "00:01:50",
          overallTime: "00:01:50",
        },
      },
      10802: {
        id: 10802,
        startno: 137,
        firstName: "Taco",
        lastName: "van der Hoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-04",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 134,
          overallPosition: 134,
          stageTime: "00:01:54",
          overallTime: "00:01:54",
        },
      },
      10382: {
        id: 10382,
        startno: 237,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-30",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 48,
          overallPosition: 48,
          stageTime: "00:01:06",
          overallTime: "00:01:06",
        },
      },
      6255: {
        id: 6255,
        startno: 204,
        firstName: "Tsgabu",
        lastName: "Grmay",
        nationCode: "ETH",
        nationName: "Ethiopia",
        birthDate: "1991-08-25",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 69,
          overallPosition: 69,
          stageTime: "00:01:16",
          overallTime: "00:01:16",
        },
      },
      16816: {
        id: 16816,
        startno: 42,
        firstName: "Phil",
        lastName: "Bauhaus",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-07-08",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 143,
          overallPosition: 143,
          stageTime: "00:02:01",
          overallTime: "00:02:01",
        },
      },
      20466: {
        id: 20466,
        startno: 54,
        firstName: "Davide",
        lastName: "Gabburo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-04-01",
        teamId: 22591,
        teamName: "Bardiani CSF Faizane",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 158,
          overallPosition: 158,
          stageTime: "00:02:22",
          overallTime: "00:02:22",
        },
      },
      7910: {
        id: 7910,
        startno: 97,
        firstName: "Thomas",
        lastName: "Scully",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-01-14",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 68,
          overallPosition: 68,
          stageTime: "00:01:15",
          overallTime: "00:01:15",
        },
      },
      117: {
        id: 117,
        startno: 91,
        firstName: "Rigoberto",
        lastName: "Uran",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1987-01-26",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 43,
          overallPosition: 43,
          stageTime: "00:01:03",
          overallTime: "00:01:03",
        },
      },
      16566: {
        id: 16566,
        startno: 76,
        firstName: "Anthony",
        lastName: "Perez",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-04-22",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 39,
          overallPosition: 39,
          stageTime: "00:01:02",
          overallTime: "00:01:02",
        },
      },
      20454: {
        id: 20454,
        startno: 74,
        firstName: "Simone",
        lastName: "Consonni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-12",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 64,
          overallPosition: 64,
          stageTime: "00:01:13",
          overallTime: "00:01:13",
        },
      },
      2982: {
        id: 2982,
        startno: 146,
        firstName: "Giacomo",
        lastName: "Nizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-01-30",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 144,
          overallPosition: 144,
          stageTime: "00:02:03",
          overallTime: "00:02:03",
        },
      },
      45992: {
        id: 45992,
        startno: 1,
        firstName: "Tadej",
        lastName: "Pogacar",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1998-09-21",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 3,
          overallPosition: 3,
          stageTime: "00:00:18",
          overallTime: "00:00:18",
        },
      },
      2211: {
        id: 2211,
        startno: 201,
        firstName: "Michael",
        lastName: "Matthews",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-09-26",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 24,
          overallPosition: 24,
          stageTime: "00:00:51",
          overallTime: "00:00:51",
        },
      },
      37401: {
        id: 37401,
        startno: 187,
        firstName: "Mikkel",
        lastName: "Honore",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-01-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 118,
          overallPosition: 118,
          stageTime: "00:01:40",
          overallTime: "00:01:40",
        },
      },
      6104: {
        id: 6104,
        startno: 191,
        firstName: "Warren",
        lastName: "Barguil",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-10-28",
        teamId: 21865,
        teamName: "Team Arkea-Samsic",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 147,
          overallPosition: 147,
          stageTime: "00:02:06",
          overallTime: "00:02:06",
        },
      },
      1006: {
        id: 1006,
        startno: 41,
        firstName: "Damiano",
        lastName: "Caruso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-10-12",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 52,
          overallPosition: 52,
          stageTime: "00:01:06",
          overallTime: "00:01:06",
        },
      },
      40963: {
        id: 40963,
        startno: 106,
        firstName: "Giovanni",
        lastName: "Lonardi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-11-09",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 149,
          overallPosition: 149,
          stageTime: "00:02:08",
          overallTime: "00:02:08",
        },
      },
      95151: {
        id: 95151,
        startno: 235,
        firstName: "Quinn",
        lastName: "Simmons",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2001-05-08",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 46,
          overallPosition: 46,
          stageTime: "00:01:05",
          overallTime: "00:01:05",
        },
      },
      9894: {
        id: 9894,
        startno: 22,
        firstName: "Michael",
        lastName: "Gogl",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-11-04",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 124,
          overallPosition: 124,
          stageTime: "00:01:45",
          overallTime: "00:01:45",
        },
      },
      33263: {
        id: 33263,
        startno: 96,
        firstName: "Jonathan",
        lastName: "Caicedo",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-03-28",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 62,
          overallPosition: 62,
          stageTime: "00:01:11",
          overallTime: "00:01:11",
        },
      },
      16639: {
        id: 16639,
        startno: 123,
        firstName: "Tao",
        lastName: "Geoghegan Hart",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-03-30",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 14,
          overallPosition: 14,
          stageTime: "00:00:42",
          overallTime: "00:00:42",
        },
      },
      1310: {
        id: 1310,
        startno: 127,
        firstName: "Elia",
        lastName: "Viviani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-02-07",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 95,
          overallPosition: 95,
          stageTime: "00:01:27",
          overallTime: "00:01:27",
        },
      },
      38146: {
        id: 38146,
        startno: 216,
        firstName: "Joris",
        lastName: "Nieuwenhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-11",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 93,
          overallPosition: 93,
          stageTime: "00:01:25",
          overallTime: "00:01:25",
        },
      },
      6228: {
        id: 6228,
        startno: 161,
        firstName: "Tim",
        lastName: "Wellens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-10",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 79,
          overallPosition: 79,
          stageTime: "00:01:20",
          overallTime: "00:01:20",
        },
      },
      6409: {
        id: 6409,
        startno: 175,
        firstName: "Lluis",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-10-15",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 22,
          overallPosition: 22,
          stageTime: "00:00:49",
          overallTime: "00:00:49",
        },
      },
      840: {
        id: 840,
        startno: 163,
        firstName: "Roger",
        lastName: "Kluge",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-02-05",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 61,
          overallPosition: 61,
          stageTime: "00:01:11",
          overallTime: "00:01:11",
        },
      },
      20384: {
        id: 20384,
        startno: 231,
        firstName: "Giulio",
        lastName: "Ciccone",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-12-20",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 83,
          overallPosition: 83,
          stageTime: "00:01:22",
          overallTime: "00:01:22",
        },
      },
      7729: {
        id: 7729,
        startno: 217,
        firstName: "Martijn",
        lastName: "Tusveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-09-09",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 123,
          overallPosition: 123,
          stageTime: "00:01:43",
          overallTime: "00:01:43",
        },
      },
      6260: {
        id: 6260,
        startno: 113,
        firstName: "Antoine",
        lastName: "Duchesne",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1991-09-12",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 113,
          overallPosition: 113,
          stageTime: "00:01:37",
          overallTime: "00:01:37",
        },
      },
      17215: {
        id: 17215,
        startno: 12,
        firstName: "Geoffrey",
        lastName: "Bouchard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-04-01",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 53,
          overallPosition: 53,
          stageTime: "00:01:07",
          overallTime: "00:01:07",
        },
      },
      11011: {
        id: 11011,
        startno: 4,
        firstName: "Davide",
        lastName: "Formolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-10-25",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 99,
          overallPosition: 99,
          stageTime: "00:01:30",
          overallTime: "00:01:30",
        },
      },
      37719: {
        id: 37719,
        startno: 234,
        firstName: "Matteo",
        lastName: "Moschetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-08-14",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 117,
          overallPosition: 117,
          stageTime: "00:01:39",
          overallTime: "00:01:39",
        },
      },
      3224: {
        id: 3224,
        startno: 62,
        firstName: "Cesare",
        lastName: "Benedetti",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1987-08-03",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 120,
          overallPosition: 120,
          stageTime: "00:01:42",
          overallTime: "00:01:42",
        },
      },
      9451: {
        id: 9451,
        startno: 85,
        firstName: "Eduardo",
        lastName: "Sepulveda",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1991-06-13",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 151,
          overallPosition: 151,
          stageTime: "00:02:15",
          overallTime: "00:02:15",
        },
      },
      10103: {
        id: 10103,
        startno: 185,
        firstName: "Josef",
        lastName: "Cerny",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1993-05-11",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 19,
          overallPosition: 19,
          stageTime: "00:00:47",
          overallTime: "00:00:47",
        },
      },
      964: {
        id: 964,
        startno: 227,
        firstName: "Julien",
        lastName: "Simon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1985-10-04",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 126,
          overallPosition: 126,
          stageTime: "00:01:46",
          overallTime: "00:01:46",
        },
      },
      777: {
        id: 777,
        startno: 225,
        firstName: "Daniel",
        lastName: "Oss",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-01-13",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 108,
          overallPosition: 108,
          stageTime: "00:01:32",
          overallTime: "00:01:32",
        },
      },
      14122: {
        id: 14122,
        startno: 101,
        firstName: "Diego",
        lastName: "Rosa",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-03-27",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 141,
          overallPosition: 141,
          stageTime: "00:01:58",
          overallTime: "00:01:58",
        },
      },
      784: {
        id: 784,
        startno: 221,
        firstName: "Peter",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1990-01-26",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 87,
          overallPosition: 87,
          stageTime: "00:01:23",
          overallTime: "00:01:23",
        },
      },
      18079: {
        id: 18079,
        startno: 203,
        firstName: "Alex",
        lastName: "Edmondson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-12-22",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 36,
          overallPosition: 36,
          stageTime: "00:01:01",
          overallTime: "00:01:01",
        },
      },
      7695: {
        id: 7695,
        startno: 94,
        firstName: "Magnus",
        lastName: "Cort",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1993-01-16",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 26,
          overallPosition: 26,
          stageTime: "00:00:53",
          overallTime: "00:00:53",
        },
      },
      161: {
        id: 161,
        startno: 192,
        firstName: "Maxime",
        lastName: "Bouet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-11-03",
        teamId: 21865,
        teamName: "Team Arkea-Samsic",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 50,
          overallPosition: 50,
          stageTime: "00:01:06",
          overallTime: "00:01:06",
        },
      },
      14128: {
        id: 14128,
        startno: 87,
        firstName: "Edoardo",
        lastName: "Zardini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-11-02",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 156,
          overallPosition: 156,
          stageTime: "00:02:20",
          overallTime: "00:02:20",
        },
      },
      16660: {
        id: 16660,
        startno: 145,
        firstName: "Krists",
        lastName: "Neilands",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1994-08-18",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 86,
          overallPosition: 86,
          stageTime: "00:01:23",
          overallTime: "00:01:23",
        },
      },
      1717: {
        id: 1717,
        startno: 156,
        firstName: "Tosh",
        lastName: "Van der Sande",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-11-28",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 128,
          overallPosition: 128,
          stageTime: "00:01:47",
          overallTime: "00:01:47",
        },
      },
      25726: {
        id: 25726,
        startno: 7,
        firstName: "Marc",
        lastName: "Soler",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1993-11-22",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 18,
          overallPosition: 18,
          stageTime: "00:00:47",
          overallTime: "00:00:47",
        },
      },
      828: {
        id: 828,
        startno: 126,
        firstName: "Ben",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-11-05",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 111,
          overallPosition: 111,
          stageTime: "00:01:35",
          overallTime: "00:01:35",
        },
      },
      3147: {
        id: 3147,
        startno: 133,
        firstName: "Andrea",
        lastName: "Pasqualon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-01-02",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 115,
          overallPosition: 115,
          stageTime: "00:01:38",
          overallTime: "00:01:38",
        },
      },
      2165: {
        id: 2165,
        startno: 61,
        firstName: "Wilco",
        lastName: "Kelderman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1991-03-25",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 42,
          overallPosition: 42,
          stageTime: "00:01:03",
          overallTime: "00:01:03",
        },
      },
      70377: {
        id: 70377,
        startno: 167,
        firstName: "Maxim",
        lastName: "Van Gils",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-11-25",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 92,
          overallPosition: 92,
          stageTime: "00:01:25",
          overallTime: "00:01:25",
        },
      },
      2278: {
        id: 2278,
        startno: 45,
        firstName: "Mikel",
        lastName: "Landa",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-12-13",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 85,
          overallPosition: 85,
          stageTime: "00:01:23",
          overallTime: "00:01:23",
        },
      },
      7731: {
        id: 7731,
        startno: 147,
        firstName: "Rick",
        lastName: "Zabel",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-12-07",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 131,
          overallPosition: 131,
          stageTime: "00:01:49",
          overallTime: "00:01:49",
        },
      },
      20471: {
        id: 20471,
        startno: 57,
        firstName: "Alessandro",
        lastName: "Tonelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-05-29",
        teamId: 22591,
        teamName: "Bardiani CSF Faizane",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 130,
          overallPosition: 130,
          stageTime: "00:01:47",
          overallTime: "00:01:47",
        },
      },
      4953: {
        id: 4953,
        startno: 132,
        firstName: "Sven Erik",
        lastName: "Byström",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1992-01-21",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 51,
          overallPosition: 51,
          stageTime: "00:01:06",
          overallTime: "00:01:06",
        },
      },
      10916: {
        id: 10916,
        startno: 84,
        firstName: "Jhonatan",
        lastName: "Restrepo",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-11-28",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 157,
          overallPosition: 157,
          stageTime: "00:02:21",
          overallTime: "00:02:21",
        },
      },
      38937: {
        id: 38937,
        startno: 75,
        firstName: "Victor",
        lastName: "Lafay",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-01-17",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 116,
          overallPosition: 116,
          stageTime: "00:01:38",
          overallTime: "00:01:38",
        },
      },
      22389: {
        id: 22389,
        startno: 17,
        firstName: "Andrea",
        lastName: "Vendrame",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-07-20",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 73,
          overallPosition: 73,
          stageTime: "00:01:16",
          overallTime: "00:01:16",
        },
      },
      46003: {
        id: 46003,
        startno: 24,
        firstName: "Stefano",
        lastName: "Oldani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-01-10",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 56,
          overallPosition: 56,
          stageTime: "00:01:08",
          overallTime: "00:01:08",
        },
      },
      706: {
        id: 706,
        startno: 144,
        firstName: "Daryl",
        lastName: "Impey",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1984-12-06",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 37,
          overallPosition: 37,
          stageTime: "00:01:01",
          overallTime: "00:01:01",
        },
      },
      16573: {
        id: 16573,
        startno: 162,
        firstName: "Caleb",
        lastName: "Ewan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-07-11",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 167,
          overallPosition: 167,
          stageTime: "00:02:51",
          overallTime: "00:02:51",
        },
      },
      27121: {
        id: 27121,
        startno: 182,
        firstName: "Kasper",
        lastName: "Asgreen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-02-08",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 4,
          overallPosition: 4,
          stageTime: "00:00:24",
          overallTime: "00:00:24",
        },
      },
      39293: {
        id: 39293,
        startno: 194,
        firstName: "Thibault",
        lastName: "Guernalec",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-07-31",
        teamId: 21865,
        teamName: "Team Arkea-Samsic",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 17,
          overallPosition: 17,
          stageTime: "00:00:45",
          overallTime: "00:00:45",
        },
      },
      3096: {
        id: 3096,
        startno: 33,
        firstName: "Manuele",
        lastName: "Boaro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-12-03",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 21,
          overallPosition: 21,
          stageTime: "00:00:48",
          overallTime: "00:00:48",
        },
      },
      580: {
        id: 580,
        startno: 105,
        firstName: "Francesco",
        lastName: "Gavazzi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1984-08-01",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 150,
          overallPosition: 150,
          stageTime: "00:02:13",
          overallTime: "00:02:13",
        },
      },
      48008: {
        id: 48008,
        startno: 155,
        firstName: "Sepp",
        lastName: "Kuss",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-09-13",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 104,
          overallPosition: 104,
          stageTime: "00:01:31",
          overallTime: "00:01:31",
        },
      },
      37446: {
        id: 37446,
        startno: 66,
        firstName: "Jai",
        lastName: "Hindley",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-05-05",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 47,
          overallPosition: 47,
          stageTime: "00:01:05",
          overallTime: "00:01:05",
        },
      },
      41249: {
        id: 41249,
        startno: 124,
        firstName: "Jhonnatan",
        lastName: "Narvaez",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1997-03-04",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 132,
          overallPosition: 132,
          stageTime: "00:01:50",
          overallTime: "00:01:50",
        },
      },
      14: {
        id: 14,
        startno: 184,
        firstName: "Mark",
        lastName: "Cavendish",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1985-05-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 101,
          overallPosition: 101,
          stageTime: "00:01:30",
          overallTime: "00:01:30",
        },
      },
      27099: {
        id: 27099,
        startno: 122,
        firstName: "Filippo",
        lastName: "Ganna",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-07-25",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 1,
          overallPosition: 1,
          stageTime: "00:15:17",
          overallTime: "00:15:17",
        },
      },
      78129: {
        id: 78129,
        startno: 212,
        firstName: "Thymen",
        lastName: "Arensman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-12-04",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 6,
          overallPosition: 6,
          stageTime: "00:00:28",
          overallTime: "00:00:28",
        },
      },
      45364: {
        id: 45364,
        startno: 67,
        firstName: "Jordi",
        lastName: "Meeus",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-01",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 129,
          overallPosition: 129,
          stageTime: "00:01:47",
          overallTime: "00:01:47",
        },
      },
      25695: {
        id: 25695,
        startno: 173,
        firstName: "Jorge",
        lastName: "Arcas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1992-07-08",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 136,
          overallPosition: 136,
          stageTime: "00:01:54",
          overallTime: "00:01:54",
        },
      },
      9344: {
        id: 9344,
        startno: 63,
        firstName: "Emanuel",
        lastName: "Buchmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-18",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 109,
          overallPosition: 109,
          stageTime: "00:01:33",
          overallTime: "00:01:33",
        },
      },
      2741: {
        id: 2741,
        startno: 232,
        firstName: "Gianluca",
        lastName: "Brambilla",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-22",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 137,
          overallPosition: 137,
          stageTime: "00:01:55",
          overallTime: "00:01:55",
        },
      },
      18564: {
        id: 18564,
        startno: 153,
        firstName: "Koen",
        lastName: "Bouwman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-02",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 110,
          overallPosition: 110,
          stageTime: "00:01:34",
          overallTime: "00:01:34",
        },
      },
      32571: {
        id: 32571,
        startno: 25,
        firstName: "Edward",
        lastName: "Planckaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-02-01",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 148,
          overallPosition: 148,
          stageTime: "00:02:07",
          overallTime: "00:02:07",
        },
      },
      12474: {
        id: 12474,
        startno: 181,
        firstName: "Julian",
        lastName: "Alaphilippe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-06-11",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 32,
          overallPosition: 32,
          stageTime: "00:00:58",
          overallTime: "00:00:58",
        },
      },
      71333: {
        id: 71333,
        startno: 214,
        firstName: "Alberto",
        lastName: "Dainese",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-03-25",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 88,
          overallPosition: 88,
          stageTime: "00:01:23",
          overallTime: "00:01:23",
        },
      },
      532: {
        id: 532,
        startno: 233,
        firstName: "Dario",
        lastName: "Cataldo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1985-03-17",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 54,
          overallPosition: 54,
          stageTime: "00:01:07",
          overallTime: "00:01:07",
        },
      },
      361: {
        id: 361,
        startno: 223,
        firstName: "Maciej",
        lastName: "Bodnar",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1985-03-07",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 23,
          overallPosition: 23,
          stageTime: "00:00:51",
          overallTime: "00:00:51",
        },
      },
      624: {
        id: 624,
        startno: 6,
        firstName: "Maximiliano",
        lastName: "Richeze",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1983-03-07",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 146,
          overallPosition: 146,
          stageTime: "00:02:06",
          overallTime: "00:02:06",
        },
      },
      64106: {
        id: 64106,
        startno: 36,
        firstName: "Harold",
        lastName: "Tejada",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-04-27",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 66,
          overallPosition: 66,
          stageTime: "00:01:14",
          overallTime: "00:01:14",
        },
      },
      51269: {
        id: 51269,
        startno: 224,
        firstName: "Valentin",
        lastName: "Ferron",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-02-08",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 41,
          overallPosition: 41,
          stageTime: "00:01:03",
          overallTime: "00:01:03",
        },
      },
      14126: {
        id: 14126,
        startno: 77,
        firstName: "Davide",
        lastName: "Villella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-06-27",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 119,
          overallPosition: 119,
          stageTime: "00:01:41",
          overallTime: "00:01:41",
        },
      },
      20386: {
        id: 20386,
        startno: 83,
        firstName: "Umberto",
        lastName: "Marengo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-07-21",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 155,
          overallPosition: 155,
          stageTime: "00:02:20",
          overallTime: "00:02:20",
        },
      },
      327: {
        id: 327,
        startno: 16,
        firstName: "Michael",
        lastName: "Schär",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 103,
          overallPosition: 103,
          stageTime: "00:01:31",
          overallTime: "00:01:31",
        },
      },
      44537: {
        id: 44537,
        startno: 26,
        firstName: "Robert",
        lastName: "Stannard",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-09-16",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 67,
          overallPosition: 67,
          stageTime: "00:01:14",
          overallTime: "00:01:14",
        },
      },
      264: {
        id: 264,
        startno: 141,
        firstName: "Jakob",
        lastName: "Fuglsang",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1985-03-22",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 40,
          overallPosition: 40,
          stageTime: "00:01:02",
          overallTime: "00:01:02",
        },
      },
      2530: {
        id: 2530,
        startno: 193,
        firstName: "Nacer",
        lastName: "Bouhanni",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-07-25",
        teamId: 21865,
        teamName: "Team Arkea-Samsic",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 142,
          overallPosition: 142,
          stageTime: "00:02:00",
          overallTime: "00:02:00",
        },
      },
      40238: {
        id: 40238,
        startno: 81,
        firstName: "Mattia",
        lastName: "Bais",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-10-19",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 163,
          overallPosition: 163,
          stageTime: "00:02:35",
          overallTime: "00:02:35",
        },
      },
      3930: {
        id: 3930,
        startno: 236,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 82,
          overallPosition: 82,
          stageTime: "00:01:21",
          overallTime: "00:01:21",
        },
      },
      6246: {
        id: 6246,
        startno: 65,
        firstName: "Marco",
        lastName: "Haller",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-04-01",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 139,
          overallPosition: 139,
          stageTime: "00:01:57",
          overallTime: "00:01:57",
        },
      },
      91583: {
        id: 91583,
        startno: 86,
        firstName: "Natnael",
        lastName: "Tesfazion",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1999-05-23",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 162,
          overallPosition: 162,
          stageTime: "00:02:30",
          overallTime: "00:02:30",
        },
      },
      1009: {
        id: 1009,
        startno: 143,
        firstName: "Alex",
        lastName: "Dowsett",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1988-10-03",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 5,
          overallPosition: 5,
          stageTime: "00:00:25",
          overallTime: "00:00:25",
        },
      },
      452: {
        id: 452,
        startno: 135,
        firstName: "Domenico",
        lastName: "Pozzovivo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1982-11-30",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 70,
          overallPosition: 70,
          stageTime: "00:01:16",
          overallTime: "00:01:16",
        },
      },
      97547: {
        id: 97547,
        startno: 154,
        firstName: "Olav",
        lastName: "Kooij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-10-17",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 140,
          overallPosition: 140,
          stageTime: "00:01:57",
          overallTime: "00:01:57",
        },
      },
      12482: {
        id: 12482,
        startno: 23,
        firstName: "Xandro",
        lastName: "Meurisse",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-01-31",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 57,
          overallPosition: 57,
          stageTime: "00:01:10",
          overallTime: "00:01:10",
        },
      },
      4863: {
        id: 4863,
        startno: 43,
        firstName: "Pello",
        lastName: "Bilbao",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-02-25",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 60,
          overallPosition: 60,
          stageTime: "00:01:10",
          overallTime: "00:01:10",
        },
      },
      6236: {
        id: 6236,
        startno: 165,
        firstName: "Rüdiger",
        lastName: "Selig",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-02-19",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 153,
          overallPosition: 153,
          stageTime: "00:02:20",
          overallTime: "00:02:20",
        },
      },
      33402: {
        id: 33402,
        startno: 53,
        firstName: "Filippo",
        lastName: "Fiorelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 22591,
        teamName: "Bardiani CSF Faizane",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 154,
          overallPosition: 154,
          stageTime: "00:02:20",
          overallTime: "00:02:20",
        },
      },
      406: {
        id: 406,
        startno: 131,
        firstName: "Alexander",
        lastName: "Kristoff",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-07-05",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 72,
          overallPosition: 72,
          stageTime: "00:01:16",
          overallTime: "00:01:16",
        },
      },
      35077: {
        id: 35077,
        startno: 31,
        firstName: "Miguel Angel",
        lastName: "Lopez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-02-04",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 13,
          overallPosition: 13,
          stageTime: "00:00:42",
          overallTime: "00:00:42",
        },
      },
      22268: {
        id: 22268,
        startno: 13,
        firstName: "Lilian",
        lastName: "Calmejane",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-06",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 76,
          overallPosition: 76,
          stageTime: "00:01:18",
          overallTime: "00:01:18",
        },
      },
      26504: {
        id: 26504,
        startno: 183,
        firstName: "Davide",
        lastName: "Ballerini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 30,
          overallPosition: 30,
          stageTime: "00:00:55",
          overallTime: "00:00:55",
        },
      },
      14717: {
        id: 14717,
        startno: 34,
        firstName: "Davide",
        lastName: "Martinelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-31",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 75,
          overallPosition: 75,
          stageTime: "00:01:18",
          overallTime: "00:01:18",
        },
      },
      16992: {
        id: 16992,
        startno: 92,
        firstName: "Ruben",
        lastName: "Guerreiro",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1994-07-06",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 78,
          overallPosition: 78,
          stageTime: "00:01:19",
          overallTime: "00:01:19",
        },
      },
    },
  }

  const race = "Tirreno Adriatico"
  const raceID = 3

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
